import React, { useState, useEffect, createContext, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import AddNew from "../components/forms/AddNew";
import Update from "../components/forms/Update";
import Header from "../components/Header";
import { userInterface } from "../interfaces/interface";
import Admin from "./Admin";
import Analytics from "./Analytics";
import Dashboard from "./dashboard";
import Timecard from "./Timecard";
import { accContext } from "../App";
import { fetchEmployees, deleteUser as supabaseDeleteUser, patchTimestamp } from "../API/apiService";
import Rates from "./Rates";

export const appContext = createContext<userInterface[] | null>(null);

export interface MainProps {
  authenticated: boolean;
  setauth(auth: boolean): void;
}

const Main: React.FC<MainProps> = ({ authenticated, setauth }) => {
  const accUser = useContext(accContext);
  const [status, setStatus] = useState<boolean | null>(null);
  const [currentUser, setCurrentUser] = useState<userInterface | null>(null);
  const [users, setUsers] = useState<userInterface[]>([]);
  const [searchResult, setSearchResult] = useState<userInterface[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    let isMounted = true; // Flag para controlar si el componente está montado
  
    if (accUser && isMounted) {
      setCurrentUser(accUser);
    }
  
    const loadEmployees = async () => {
      if (!accUser) return; // Asegúrate de que accUser está definido
  
      try {
        const response = await fetchEmployees(accUser.company_id, accUser.admin);
        if (isMounted) { // Verifica si el componente sigue montado antes de actualizar el estado
          if (response.success) {
            setUsers(response.data);
            setCurrentUser(accUser);
          } else {
            console.error("Failed to fetch employees");
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
  
    loadEmployees();
  
    return () => {
      isMounted = false; // Limpieza: marcar como desmontado
    };
  }, [accUser]); // Dependencias

  const deleteUser = async (id: number): Promise<void> => {
    try {
      const response = await supabaseDeleteUser(id);
      if (response.success) {
        const updatedUsers = users.filter((user) => user.id !== id);
        setUsers(updatedUsers);
        setCurrentUser(null); // Actualiza el estado del usuario actual si es necesario
        setStatus(true);
        setTimeout(() => setStatus(null), 2500);
      } else {
        setStatus(false);
        setTimeout(() => setStatus(null), 2500);
      }
    } catch (err) {
      console.error(err);
      alert("Task Failed :(");
    }
  };

  const postTimeStamp = async (id: number, formData: userInterface): Promise<void> => {
    try {
      const response = await patchTimestamp(id, formData);
      if (!response.success) {
        console.error("Failed to update timestamp");
      }
    } catch (err) {
      console.error(err);
      alert("Task Failed :(");
    }
  };

  const filterUsers = (str: string) => {
    setSearchTerm(str);
    if (str !== "") {
      const newList = users.filter((user) =>
        Object.values(user)
          .join(" ")
          .toLowerCase()
          .includes(str.toLowerCase())
      );
      setSearchResult(newList);
    } else {
      setSearchResult(users);
    }
  };

  return (
    <appContext.Provider value={searchTerm.length < 1 ? users : searchResult}>
      <section className="container__main">
        <Header authenticated={authenticated} setauth={setauth} />
        <div className="view__main">
          <Routes>
            <Route
              path="dashboard"
              element={<Dashboard filterUsers={filterUsers} />}
            />
            <Route
              path="admin"
              element={
                <Admin
                  currentuser={currentUser}
                  setCurrentUser={setCurrentUser}
                  filterUsers={filterUsers}
                  deleteUser={deleteUser}
                  status={status}
                />
              }
            >
              <Route
                path="Addnew"
                element={
                  <AddNew
                    setUsers={setUsers}
                    users={users}
                    setCurrentUser={setCurrentUser}
                  />
                }
              />
              <Route
                path="/admin/"
                element={
                  <Update
                    currentuser={currentUser}
                    setCurrentUser={setCurrentUser}
                    setUsers={setUsers}
                    users={users}
                  />
                }
              />
              <Route
                path="update"
                element={
                  <Update
                    currentuser={currentUser}
                    setCurrentUser={setCurrentUser}
                    setUsers={setUsers}
                    users={users}
                  />
                }
              />
            </Route>
            <Route
              path="analytics"
              element={
                <Analytics
                  currentuser={currentUser}
                  setCurrentUser={setCurrentUser}
                />
              }
            />
            <Route
              path="timecard"
              element={
                <Timecard
                  currentuser={currentUser}
                  setCurrentUser={setCurrentUser}
                  postTimeStamp={postTimeStamp}
                  filterUsers={filterUsers}
                />
              }
            />
            <Route
              path="rates"
              element={
                <Rates/>
              }
            />
          </Routes>
        </div>
        <Footer />
      </section>
    </appContext.Provider>
  );
};

export default Main;
