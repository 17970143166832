import { NavLink } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { ReactSession } from "react-client-session";
import {
  MdAdminPanelSettings,
  MdAnalytics,
  MdOutlineAccessTimeFilled,
  MdOutlineClose,
} from "react-icons/md";
import { IoTimer } from "react-icons/io5";
import { accContext } from "../../App";

const Aside: React.FC<{ className?: string }> = () => {
  const [, setUsertype] = useState<boolean>(false);
  const accUser = useContext(accContext);

  //toggle themes

  useEffect(() => {
    setUsertype(ReactSession.get("usertype"));
  }, []);

  return (
    <aside>
      <div className="top dark-mode">
        <div className="logo">
          <IoTimer className="logo__icon" />
          <h2>Clock-Me</h2>
        </div>
        <div className="close">
          <MdOutlineClose />
        </div>
      </div>

      <fieldset className="side-bar" title="manage">
        <legend>SECCIONES</legend>

        {/* <NavLink className="nav__link" to="/home/dashboard">
          <MdSpaceDashboard />
          <h3>Dashboard</h3>
        </NavLink>

        {usertype === true ? (
          <NavLink className="nav__link" to="/home/admin">
            <MdAdminPanelSettings />
            <h3>Admin</h3>
          </NavLink>
        ) : (
          <NavLink
            className="nav__link__muted"
            //prevent admin acces to non-admin users
            onClick={(e) => e.preventDefault()}
            to="/home/admin"
          >
            <MdAdminPanelSettings />
            <h3>Login As Admin!</h3>
          </NavLink>
        )} */}
        
        {accUser.admin ? (
          <NavLink className="nav__link" to="/home/admin">
            <MdAdminPanelSettings />
            <h3>Empleados</h3>
          </NavLink>
        ) : null}

        {accUser.admin ? (
          <NavLink className="nav__link" to="/home/analytics">
            <MdAnalytics />
            <h3>Tabla de Registros</h3>
          </NavLink>
        ) : null}

        {!accUser.admin ? (
          <NavLink className="nav__link" to="/home/timecard">
            <MdOutlineAccessTimeFilled />
            <h3>Registros Horarios</h3>
          </NavLink>
        ) : null}
        {accUser.admin ? (
          <NavLink className="nav__link" to="/home/rates">
            <MdAnalytics />
            <h3>Tarifas</h3>
          </NavLink>
        ) : null}
      </fieldset>
      {/* <fieldset className="preference" title="preference">
        <legend>PREFRENCES</legend>
        <div className="content-wrapper">
          <h3>Theme</h3>
          <div className="theme-toggler" onClick={handleToggle}>
            <span className={isActive ? "active" : ""}>
              <MdWbSunny />
            </span>
            <span className={!isActive ? "active" : ""}>
              <BsMoonFill />
            </span>
          </div>
        </div>
      </fieldset>

      <div className="aside__footer">
        <div className="profile">
          <div className="profile-photo">
            <img src={accUser?.avatar} alt="Img" />
          </div>
          <div className="info">
            <p>
              <b>{accUser?.name}</b>
            </p>
            <small className="text-muted">
              {accUser?.usertype ? "Admin" : "User"}
            </small>
          </div>
        </div>
      </div> */}
    </aside>
  );
};
export default Aside;
