// App.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Aside from './components/nav/Aside';
import Main from './pages/Main';
import { userInterface } from './interfaces/interface';
import { useLoader } from './utils/LoaderContext'; // Ajusta la ruta según corresponda
import './scss/style.scss';
import { cancelSubscription, createCheckoutSession, getActiveSubscription, getCustomerIdByEmployeeId, getRateSelected, setRateSelected } from './API/apiService';

export const accContext = React.createContext<userInterface | undefined>(undefined);

const AppContent: React.FC = () => {
    const [acc, setAcc] = React.useState<userInterface | undefined>(undefined);
    const [authenticated, setAuthenticated] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [activeProduct, setActiveProduct] = React.useState<string | null>(null);
    const [activeSubscription, setActiveSubscription] = React.useState<string | null>(null);
    const [selectedRate, setSelectedRate] = React.useState<boolean | null>(false);
    const navigate = useNavigate();
    const { isLoading, setLoading: setLoaderLoading } = useLoader();

    React.useEffect(() => {
        const loadUserData = async () => {
            setLoaderLoading(true);

            const loggedUser: userInterface = JSON.parse(localStorage.getItem("name") || "{}");
            const employeeData: Partial<userInterface> = JSON.parse(localStorage.getItem("employeeData") || "{}");
            const auth: boolean = JSON.parse(localStorage.getItem("authenticated") || "false");

            if (auth && loggedUser) {
                setAuthenticated(auth);
                setAcc({ ...loggedUser, ...employeeData });

                if (employeeData) {
                    localStorage.setItem("employeeData", JSON.stringify(employeeData));
                }

                const getRateSelectedValue = await getRateSelected(employeeData.id);
                if (getRateSelectedValue) {
                    setSelectedRate(true)
                }

                // Obtener el customerId a partir del employee_id (acc.id)
                const customerId = await getCustomerIdByEmployeeId(employeeData.id);
                
                if (!customerId) {
                    console.error('Customer ID not found');
                    return;
                }

                // Verificar la suscripción activa del usuario en Stripe
                const activeProduct = await getActiveSubscription(customerId, employeeData.id);

                if (activeProduct) {
                    setActiveProduct(activeProduct.plan.product); // Si hay una suscripción activa, guarda el ID
                    setActiveSubscription(activeProduct.id); // Si hay una suscripción activa, guarda el ID
                }
            } else {
                navigate("/");
            }

            setLoaderLoading(false);
            setLoading(false);
        };

        loadUserData();
    }, [navigate, setLoaderLoading]);

    const handleClosePopup = async () => {
        await setRateSelected(acc.id);
        const customerId = await getCustomerIdByEmployeeId(acc.id);
        getActiveSubscription(customerId, acc.id);
        setSelectedRate(false);
        navigate("/home");
    };

    const handleCheckout = async (productId: string, rate_type: number) => {
        if (!acc || !acc.id || !acc.email) {
            console.error('No user logged in');
            return;
        }
    
        try {
            const sessionUrl = await createCheckoutSession(productId, acc.id, acc.email, rate_type);
            
            if (sessionUrl) {
                window.open(sessionUrl);
            } else {
                console.error('No session URL returned');
            }
        } catch (error) {
            console.error('Error during checkout:', error);
        }
    };

    const handleCancelSubscription = async () => {
        if (!activeSubscription) return;
    
        try {
            const result = await cancelSubscription(activeSubscription, acc.id);
            
            if (result.status === 'canceled') {
                setActiveProduct(null);
                alert('Suscripción cancelada');
            }
        } catch (error) {
            console.error('Error al cancelar suscripción:', error);
        }
    };

    if (loading || isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <accContext.Provider value={acc}>
            <main className="dark-mode">
                <Aside />
                <Main authenticated={authenticated} setauth={setAuthenticated} />
                {/* <button onClick={handleOpenPopup}>Abrir Pop-up</button> */}
                {!selectedRate && (
                    <div className="popup-overlay">
                        <div className="popup-content">
                            {/* <button onClick={handleClosePopup}>Cerrar Pop-up</button> */}
                            <div className="container group">
                                <div className="grid-1-5">
                                    <h2>Básico</h2>
                                    <h3><span className="uppercase">Gratuito</span></h3>
                                    <p>Hasta 3 empleados</p>
                                    <ul>
                                        <li>✔️ Registros horarios en formato pdf</li>
                                        <li>❌ Modificación de registros horarios</li>
                                        <li>❌ Soporte por correo prioritario</li>
                                    </ul>
                                    <div onClick={handleClosePopup} className="button">Continuar</div>
                                </div>
                                <div className="grid-1-5">
                                    <h2>Startup</h2>
                                    <h3><sup>€</sup>4.99<span className="small">/mes</span></h3>
                                    <p>Hasta 5 empleados</p>
                                    <ul>
                                        <li>✔️ Registros horarios en formato pdf</li>
                                        <li>✔️ Modificación de registros horarios</li>
                                        <li>❌ Soporte por correo prioritario</li>
                                    </ul>       
                                    {activeProduct ===  'prod_QnPTfvPdFHHqBf'
                                        ? <div onClick={handleCancelSubscription} className="button">Cancelar</div>  
                                        : <div onClick={() => handleCheckout('prod_QnPTfvPdFHHqBf', 2)} className="button">Continuar</div>}
                                </div>
                                <div className="grid-1-5">
                                    <h2>Pyme</h2>
                                    <h3><sup>€</sup>9.99<span className="small">/mes</span></h3>
                                    <p>Hasta 10 empleados</p>
                                    <ul>
                                        <li>✔️ Registros horarios en formato pdf</li>
                                        <li>✔️ Modificación de registros horarios</li>
                                        <li>❌ Soporte por correo prioritario</li>
                                    </ul>     
                                    {activeProduct ===  'prod_QpG7QlWXqj3u7x' 
                                        ? <div onClick={handleCancelSubscription} className="button">Cancelar</div>  
                                        : <div onClick={() => handleCheckout('prod_QpG7QlWXqj3u7x', 3)} className="button">Continuar</div>}
                                </div>
                                <div className="grid-1-5">
                                    <h2>Premium</h2>
                                    <h3><sup>€</sup>15<span className="small">/mes</span></h3>
                                    <p>Hasta 20 empleados</p>
                                    <ul>
                                        <li>✔️ Registros horarios en formato pdf</li>
                                        <li>✔️ Modificación de registros horarios</li>
                                        <li>✔️ Soporte por correo prioritario</li>
                                    </ul>   
                                    {activeProduct ===  'prod_QpG9bvrLR8t7R5'  
                                        ? <div onClick={handleCancelSubscription} className="button">Cancelar</div>  
                                        : <div onClick={() => handleCheckout('prod_QpG9bvrLR8t7R5', 4)} className="button">Continuar</div>}
                                </div>
                                <div className="grid-1-5">
                                    <h2>Enterprise</h2>
                                    <h3><span className="small">Contacta con info@jornadalaboral.app</span></h3>
                                    <p>Más de 20 empleados</p>
                                    <ul>
                                        <li>✔️ Registros horarios en formato pdf</li>
                                        <li>✔️ Modificación de registros horarios</li>
                                        <li>✔️ Soporte por correo prioritario</li>
                                    </ul> 
                                    <div onClick={handleClosePopup} className="button">Continuar</div>
                                </div>        
                            </div>
                        </div>
                    </div>
                )}
            </main>
        </accContext.Provider>
    );
};

const App: React.FC = () => <AppContent />;

export default App;
