import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../scss/style.scss';
import { cancelSubscription, createCheckoutSession, getActiveSubscription, getCustomerIdByEmployeeId, setRateSelected } from '../API/apiService';
import { userInterface } from '../interfaces/interface';
import { useLoader } from '../utils/LoaderContext';

export const accContext = React.createContext<userInterface | undefined>(undefined);

const Rates: React.FC = () => {
    const [acc, setAcc] = React.useState<userInterface | undefined>(undefined);
    const [loading] = React.useState<boolean>(false);
    const [activeProduct, setActiveProduct] = React.useState<string | null>(null);
    const [activeSubscription, setActiveSubscription] = React.useState<string | null>(null);
    const navigate = useNavigate();
    const { isLoading, setLoading: setLoaderLoading } = useLoader();

    React.useEffect(() => {
        const loadUserData = async () => {    
            const loggedUser: userInterface = JSON.parse(localStorage.getItem("name") || "{}");
            const employeeData: Partial<userInterface> = JSON.parse(localStorage.getItem("employeeData") || "{}");
            const auth: boolean = JSON.parse(localStorage.getItem("authenticated") || "false");
    
            if (auth && loggedUser) {
                if (!acc) {
                    setAcc({ ...loggedUser, ...employeeData });
                }
    
                if (!activeProduct && employeeData.id) {
                    try {
                        const customerId = await getCustomerIdByEmployeeId(employeeData.id);
    
                        if (!customerId) {
                            console.error('Customer ID not found');
                            return;
                        }

                        const activeSubscription = await getActiveSubscription(customerId, employeeData.id);
    
                        // Verificar si el componente sigue montado después de obtener la suscripción
                        if (activeSubscription) {
                            setActiveProduct(activeSubscription.plan.product);
                            setActiveSubscription(activeSubscription.id);
                            console.log('pasa');
                        }
                    } catch (error) {
                        console.error('Error loading subscription data:', error);
                    }
                }
            } else {
                navigate("/");
            }
        };
    
        loadUserData();
    }, [acc, activeProduct, navigate, setLoaderLoading]);

    const handleClosePopup = async () => {
        await setRateSelected(acc?.id);
        const customerId = await getCustomerIdByEmployeeId(acc?.id);
        getActiveSubscription(customerId, acc?.id);
        navigate("/home");
    };

    const handleCheckout = async (productId: string, rate_type: number) => {
        if (!acc || !acc.id || !acc.email) {
            console.error('No user logged in');
            return;
        }

        try {
            const sessionUrl = await createCheckoutSession(productId, acc.id, acc.email, rate_type);
            
            if (sessionUrl) {
                window.open(sessionUrl);
            } else {
                console.error('No session URL returned');
            }
        } catch (error) {
            console.error('Error during checkout:', error);
        }
    };

    const handleCancelSubscription = async () => {
        if (!activeSubscription) return;

        try {
            const result = await cancelSubscription(activeSubscription, acc?.id);
            if (result.status === 'canceled') {
                setActiveProduct(null);
                alert('Suscripción cancelada');
            }
        } catch (error) {
            console.error('Error al cancelar suscripción:', error);
        }
    };

    if (loading || isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <div className="popup-overlay">
                        <div className="popup-content">
                            {/* <button onClick={handleClosePopup}>Cerrar Pop-up</button> */}
                            <div className="container group">
                            <div className="grid-1-5">
                                    <h2>Básico</h2>
                                    <h3><span className="uppercase">Gratuito</span></h3>
                                    <p>Hasta 3 empleados</p>
                                    <ul>
                                        <li>✔️ Registros horarios en formato pdf</li>
                                        <li>❌ Modificación de registros horarios</li>
                                        <li>❌ Soporte por correo prioritario</li>
                                    </ul>
                                    <div onClick={handleClosePopup} className="button">Continuar</div>
                                </div>
                                <div className="grid-1-5">
                                    <h2>Startup</h2>
                                    <h3><sup>€</sup>4.99<span className="small">/mes</span></h3>
                                    <p>Hasta 5 empleados</p>
                                    <ul>
                                        <li>✔️ Registros horarios en formato pdf</li>
                                        <li>✔️ Modificación de registros horarios</li>
                                        <li>❌ Soporte por correo prioritario</li>
                                    </ul>       
                                    {activeProduct ===  'prod_QnPTfvPdFHHqBf'
                                        ? <div onClick={handleCancelSubscription} className="button">Cancelar</div>  
                                        : <div onClick={() => handleCheckout('prod_QnPTfvPdFHHqBf', 2)} className="button">Continuar</div>}
                                </div>
                                <div className="grid-1-5">
                                    <h2>Pyme</h2>
                                    <h3><sup>€</sup>9.99<span className="small">/mes</span></h3>
                                    <p>Hasta 10 empleados</p>
                                    <ul>
                                        <li>✔️ Registros horarios en formato pdf</li>
                                        <li>✔️ Modificación de registros horarios</li>
                                        <li>❌ Soporte por correo prioritario</li>
                                    </ul>     
                                    {activeProduct ===  'prod_QpG7QlWXqj3u7x' 
                                        ? <div onClick={handleCancelSubscription} className="button">Cancelar</div>  
                                        : <div onClick={() => handleCheckout('prod_QpG7QlWXqj3u7x', 3)} className="button">Continuar</div>}
                                </div>
                                <div className="grid-1-5">
                                    <h2>Premium</h2>
                                    <h3><sup>€</sup>15<span className="small">/mes</span></h3>
                                    <p>Hasta 20 empleados</p>
                                    <ul>
                                        <li>✔️ Registros horarios en formato pdf</li>
                                        <li>✔️ Modificación de registros horarios</li>
                                        <li>✔️ Soporte por correo prioritario</li>
                                    </ul>   
                                    {activeProduct ===  'prod_QpG9bvrLR8t7R5'  
                                        ? <div onClick={handleCancelSubscription} className="button">Cancelar</div>  
                                        : <div onClick={() => handleCheckout('prod_QpG9bvrLR8t7R5', 4)} className="button">Continuar</div>}
                                </div>
                                <div className="grid-1-5">
                                    <h2>Enterprise</h2>
                                    <h3><span className="small">Contacta con info@jornadalaboral.app</span></h3>
                                    <p>Más de 20 empleados</p>
                                    <ul>
                                        <li>✔️ Registros horarios en formato pdf</li>
                                        <li>✔️ Modificación de registros horarios</li>
                                        <li>✔️ Soporte por correo prioritario</li>
                                    </ul> 
                                    <div onClick={handleClosePopup} className="button">Continuar</div>
                                </div>        
                            </div>
                        </div>
                    </div>
    );
};

export default Rates;
