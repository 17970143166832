import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { accContext } from "../../App";
const Nav:React.FC = () => {
  const accUser = useContext(accContext);
  return (
    <nav className="mobileNav">
      {/* <NavLink className="nav__link" to="/home/dashboard">
        <h3>Dashboard</h3>
      </NavLink>
      <NavLink className="nav__link" to="/home/admin">
        <h3>Admin</h3>
      </NavLink> */}
      {accUser.admin ? (
        <NavLink className="nav__link" to="/home/analytics">
          <h3>Tabla de Registros</h3>
        </NavLink>
      ) : null}
      {!accUser.admin ? (
        <NavLink className="nav__link" to="/home/timecard">
          <h3>Registros Horarios</h3>
        </NavLink>
      ) : null}
      {accUser.admin ? (
          <NavLink className="nav__link" to="/home/rates">
            <h3>Tarifas</h3>
          </NavLink>
        ) : null}
    </nav>
  );
};

export default Nav;
