import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CalendarPopup = ({ selectedDate, handleDateChange }) => {
    const [hoveredDate, setHoveredDate] = useState(null);

    const handleDayMouseEnter = (date) => {
        setHoveredDate(date);
    };

    // Calcular el inicio de la semana (lunes)
    const getStartOfWeek = (date) => {
        const startOfWeek = new Date(date);
        const day = startOfWeek.getDay(); // Obtener el día de la semana (0 = domingo, 1 = lunes, etc.)
        const diff = startOfWeek.getDate() - day + (day === 0 ? -6 : 1); // Ajustar si es domingo
        return new Date(startOfWeek.setDate(diff)); // Volver al lunes
    };

    // Cuando el usuario selecciona una fecha, seleccionamos el inicio de la semana
    const handleSelect = (date) => {
        const startOfWeek = getStartOfWeek(date);
        handleDateChange(startOfWeek); // Llamar la función con el lunes de la semana seleccionada
    };

    // Determinar si una fecha está en la semana seleccionada
    const isSelectedWeek = (date) => {
        const startOfWeek = getStartOfWeek(selectedDate);
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // Fin de la semana
        return date >= startOfWeek && date <= endOfWeek; // Verificar si la fecha está dentro de la semana
    };

    // Para destacar visualmente la semana al hacer hover
    const isHoveredWeek = (date) => {
        if (!hoveredDate) return false;
        const startOfWeek = getStartOfWeek(hoveredDate);
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        return date >= startOfWeek && date <= endOfWeek;
    };

    return (
        <DatePicker
            selected={selectedDate}
            onChange={handleSelect} // Usar handleSelect para seleccionar la semana
            inline={true}
            dateFormat="dd/MM/yyyy"
            className="custom-datepicker"
            dayClassName={date => isSelectedWeek(date) ? "selected-week" : isHoveredWeek(date) ? "hovered-week" : ""}
            onDayMouseEnter={handleDayMouseEnter}
            fixedHeight
            calendarStartDay={1}
        />
    );
}

export default CalendarPopup;
